import React, { useEffect, useState } from "react";
import {
    FiArrowRight,
    FiBarChart2,
    FiChevronDown,
    FiHome,
    FiPieChart,
} from "react-icons/fi";
import { AnimatePresence, motion } from "framer-motion";

// ShiftingDropDown component
export const ShiftingDropDown: React.FC = () => {
    return (
        <Tabs />
    );
};

// Types for Tab and Tab Content
type TabId = number | null;
type Direction = "l" | "r" | null;

// Tabs component
const Tabs: React.FC = () => {
    const [selected, setSelected] = useState<TabId>(null);
    const [dir, setDir] = useState<Direction>(null);

    const handleSetSelected = (val: TabId) => {
        if (typeof selected === "number" && typeof val === "number") {
            setDir(selected > val ? "r" : "l");
        } else if (val === null) {
            setDir(null);
        }

        setSelected(val);
    };

    return (
        <div
            onMouseLeave={() => handleSetSelected(null)}
            className="relative flex h-fit gap-2"
        >
            {TABS.map((t) => (
                <Tab
                    key={t.id}
                    selected={selected}
                    handleSetSelected={handleSetSelected}
                    tab={t.id}
                >
                    {t.title}
                </Tab>
            ))}

            <AnimatePresence>
                {selected !== null && <Content dir={dir} selected={selected} />}
            </AnimatePresence>
        </div>
    );
};

// Tab component
type TabProps = {
    children: React.ReactNode;
    tab: number;
    handleSetSelected: (val: TabId) => void;
    selected: TabId;
};

const Tab: React.FC<TabProps> = ({ children, tab, handleSetSelected, selected }) => {
    return (
        <button
            id={`shift-tab-${tab}`}
            onMouseEnter={() => handleSetSelected(tab)}
            onClick={() => handleSetSelected(tab)}
            className={`flex items-center gap-1 rounded-full px-3 py-1.5 text-sm transition-colors ${selected === tab ? "bg-neutral-800 text-neutral-100" : "text-neutral-400"
                }`}
        >
            <span>{children}</span>
            <FiChevronDown
                className={`transition-transform ${selected === tab ? "rotate-180" : ""}`}
            />
        </button>
    );
};

// Content component
type ContentProps = {
    selected: TabId;
    dir: Direction;
};

const Content: React.FC<ContentProps> = ({ selected, dir }) => {
    return (
        <motion.div
            id="overlay-content"
            initial={{
                opacity: 0,
                y: 8,
            }}
            animate={{
                opacity: 1,
                y: 0,
            }}
            exit={{
                opacity: 0,
                y: 8,
            }}
            className="absolute left-0 top-[calc(100%_+_24px)] w-96 rounded-lg border border-neutral-600 bg-gradient-to-b from-neutral-900 via-neutral-900 to-neutral-800 p-4"
        >
            <Bridge />
            <Nub selected={selected} />

            {TABS.map((t) => {
                return (
                    <div className="overflow-hidden" key={t.id}>
                        {selected === t.id && (
                            <motion.div
                                initial={{
                                    opacity: 0,
                                    x: dir === "l" ? 100 : dir === "r" ? -100 : 0,
                                }}
                                animate={{ opacity: 1, x: 0 }}
                                transition={{ duration: 0.25, ease: "easeInOut" }}
                            >
                                <t.Component />
                            </motion.div>
                        )}
                    </div>
                );
            })}
        </motion.div>
    );
};

// Bridge component
const Bridge: React.FC = () => (
    <div className="absolute -top-[24px] left-0 right-0 h-[24px]" />
);

// Nub component
type NubProps = {
    selected: TabId;
};

const Nub: React.FC<NubProps> = ({ selected }) => {
    const [left, setLeft] = useState<number>(0);

    useEffect(() => {
        moveNub();
    }, [selected]);

    const moveNub = () => {
        if (selected === null) return;

        const hoveredTab = document.getElementById(`shift-tab-${selected}`);
        const overlayContent = document.getElementById("overlay-content");

        if (!hoveredTab || !overlayContent) return;

        const tabRect = hoveredTab.getBoundingClientRect();
        const { left: contentLeft } = overlayContent.getBoundingClientRect();

        const tabCenter = tabRect.left + tabRect.width / 2 - contentLeft;

        setLeft(tabCenter);
    };

    return (
        <motion.span
            style={{
                clipPath: "polygon(0 0, 100% 0, 50% 50%, 0% 100%)",
            }}
            animate={{ left }}
            transition={{ duration: 0.25, ease: "easeInOut" }}
            className="absolute left-1/2 top-0 h-4 w-4 -translate-x-1/2 -translate-y-1/2 rotate-45 rounded-tl border border-neutral-600 bg-neutral-900"
        />
    );
};

// Products component
const Products: React.FC = () => {
    return (
        <div>
            <div className="flex gap-4">
                <div>
                    <h3 className="mb-2 text-sm font-medium">Startup</h3>
                    <a href="#" className="mb-1 block text-sm text-neutral-400">
                        Bookkeeping
                    </a>
                    <a href="#" className="block text-sm text-neutral-400">
                        Invoicing
                    </a>
                </div>
                <div>
                    <h3 className="mb-2 text-sm font-medium">Scaleup</h3>
                    <a href="#" className="mb-1 block text-sm text-neutral-400">
                        Live Coaching
                    </a>
                    <a href="#" className="mb-1 block text-sm text-neutral-400">
                        Reviews
                    </a>
                    <a href="#" className="block text-sm text-neutral-400">
                        Tax/VAT
                    </a>
                </div>
                <div>
                    <h3 className="mb-2 text-sm font-medium">Enterprise</h3>
                    <a href="#" className="mb-1 block text-sm text-neutral-400">
                        White glove
                    </a>
                    <a href="#" className="mb-1 block text-sm text-neutral-400">
                        SOX Compliance
                    </a>
                    <a href="#" className="block text-sm text-neutral-400">
                        Staffing
                    </a>
                    <a href="#" className="block text-sm text-neutral-400">
                        More
                    </a>
                </div>
            </div>

            <button className="ml-auto mt-4 flex items-center gap-1 text-sm text-indigo-300">
                <span>View more</span>
                <FiArrowRight />
            </button>
        </div>
    );
};

// Pricing component
const Pricing: React.FC = () => {
    return (
        <div className="grid grid-cols-3 gap-4 divide-x divide-neutral-700">
            <a
                href="#"
                className="flex w-full flex-col items-center justify-center py-2 text-neutral-400 transition-colors hover:text-neutral-50"
            >
                <FiHome className="mb-2 text-xl text-indigo-300" />
                <span className="text-xs">Startup</span>
            </a>
            <a
                href="#"
                className="flex w-full flex-col items-center justify-center py-2 text-neutral-400 transition-colors hover:text-neutral-50"
            >
                <FiBarChart2 className="mb-2 text-xl text-indigo-300" />
                <span className="text-xs">Scaleup</span>
            </a>
            <a
                href="#"
                className="flex w-full flex-col items-center justify-center py-2 text-neutral-400 transition-colors hover:text-neutral-50"
            >
                <FiPieChart className="mb-2 text-xl text-indigo-300" />
                <span className="text-xs">Enterprise</span>
            </a>
        </div>
    );
};

// Blog component
const Blog: React.FC = () => {
    return (
        <div>
            <div className="grid grid-cols-2 gap-2">
                <a href="#">
                    <img
                        className="mb-2 h-14 w-full rounded object-cover"
                        src="/imgs/blog/4.png"
                        alt="Placeholder image"
                    />
                    <h4 className="mb-0.5 text-sm font-medium">Lorem ipsum dolor</h4>
                    <p className="text-xs text-neutral-400">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet illo
                        quidem eos.
                    </p>
                </a>
                <a href="#">
                    <img
                        className="mb-2 h-14 w-full rounded object-cover"
                        src="/imgs/blog/5.png"
                        alt="Placeholder image"
                    />
                    <h4 className="mb-0.5 text-sm font-medium">Lorem ipsum dolor</h4>
                    <p className="text-xs text-neutral-400">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet illo
                        quidem eos.
                    </p>
                </a>
            </div>
            <button className="ml-auto mt-4 flex items-center gap-1 text-sm text-indigo-300">
                <span>View more</span>
                <FiArrowRight />
            </button>
        </div>
    );
};

// Tab data
const TABS = [
    {
        title: "Products",
        Component: Products,
    },
    {
        title: "Pricing",
        Component: Pricing,
    },
    {
        title: "Blog",
        Component: Blog,
    },
].map((n, idx) => ({ ...n, id: idx + 1 }));
